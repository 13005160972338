import React from "react";
import logo from "../Images/logo.png";
import GSTN_logo from "../Images/GSTN_venba.png";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="d-flex justify-content-center mt-2">
        <Link to="/">
          <img
            src={logo}
            alt="logo"
            style={{ width: "234px", height: "130px" }}
          />
        </Link>
      </div>
      <div
        className="d-flex justify-content-center align-items-center gap-2"
        style={{ height: "50px" }}
      >
        <img
          src={GSTN_logo}
          alt="GST"
          style={{ width: "40px", height: "40px" }}
        />
        <p style={{ margin: 0, lineHeight: "40px", fontSize: "15px" }}>
          GSTIN : 33AKTPV6692C1ZJ
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          margin: "20px",
        }}
      >
        <h2 className="text-center mb-4">Privacy Policy</h2>

        <h5>1. Data Collection</h5>
        <div>
          <p>
            We collect and store personal details solely for order processing
            and communication. These details include:
          </p>
          <ul>
            <li>Name</li>
            <li>Contact information</li>
            <li>Payment details</li>
          </ul>
        </div>
        <h5>2. Data Usage</h5>
        <div>
          <p>
            The information provided by buyers will be used for the following
            purposes:
          </p>
          <ol>
            <li>Processing transactions</li>
            <li>Coordinating deliveries</li>
            <li>Sending promotional updates (only with the buyer’s consent)</li>
          </ol>
        </div>
        <h5>3. Data Protection</h5>
        <div>
          <p>
            We are committed to ensuring the security of your personal
            information. Your data will not be shared with third parties unless:
          </p>
          <ol>
            <li>It is required for delivery purposes</li>
            <li>It is necessary to comply with legal obligations</li>
          </ol>
        </div>
        <h5>4. Cookies</h5>
        <div>
          <p>Our website uses cookies to:</p>
          <ol>
            <li>Enhance user experience</li>
            <li>Monitor and track website performance</li>
          </ol>
        </div>
        <h5>5. Policy Updates</h5>
        <div>
          <p>
            This Privacy Policy may be updated periodically. Any changes will be
            communicated through our website.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
