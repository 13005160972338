import React from "react";
import logo from "../Images/logo.png";
import GSTN_logo from "../Images/GSTN_venba.png";
import { Link } from "react-router-dom";

const RefundCancellation = () => {
  return (
    <div class="container">
      <div className="d-flex justify-content-center mt-2">
        <Link to="/">
          <img
            src={logo}
            alt="logo"
            style={{ width: "234px", height: "130px" }}
          />
        </Link>
      </div>
      <div
        className="d-flex justify-content-center align-items-center gap-2"
        style={{ height: "50px" }}
      >
        <img
          src={GSTN_logo}
          alt="GST"
          style={{ width: "40px", height: "40px" }}
        />
        <p style={{ margin: 0, lineHeight: "40px", fontSize: "15px" }}>
          GSTIN : 33AKTPV6692C1ZJ
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          margin: "20px",
        }}
      >
        <h2 className="text-center mb-4">Refund and Cancellation Policy</h2>

        <h5>1. Refund Eligibility</h5>
        <ul>
          <li>
            Refunds will not be issued for reasons such as negligence, misuse,
            or mishandling of the product.
          </li>
          <li>
            Refunds are only applicable if delivery is delayed beyond <strong>40 days</strong>
            due to <strong>force majeure</strong> circumstances.
          </li>
        </ul>

        <h5>2. Refund Process</h5>
        <ul>
          <li>
            If eligible, refunds will be processed excluding delivery and return
            costs.
          </li>
          <li>
            Buyers must provide a <strong>video recording</strong> of the defect upon opening the
            delivery package to initiate a refund or replacement request.
          </li>
        </ul>

        <h5>3. Cancellation Policy</h5>
        <ul>
          <li>
            Orders can be canceled <strong>only before the manufacturing process begins.</strong>
          </li>
          <li>
            Once the manufacturing process has started, cancellations will no
            longer be accepted.
          </li>
        </ul>

        <h5>4. Force Majeure</h5>
        <ul>
          <li>
            In the event of delays exceeding <strong>40 days</strong> due to force majeure, the
            <strong>value of the stone</strong> will be refunded.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default RefundCancellation;
