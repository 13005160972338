import React from "react";
import logo from "../Images/logo.png";
import GSTN_logo from "../Images/GSTN_venba.png";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div className="container">
      <div className="d-flex justify-content-center mt-2">
        <Link to="/">
          <img
            src={logo}
            alt="logo"
            style={{ width: "234px", height: "130px" }}
          />
        </Link>
      </div>
      <div
        className="d-flex justify-content-center align-items-center gap-2"
        style={{ height: "50px" }}
      >
        <img
          src={GSTN_logo}
          alt="GST"
          style={{ width: "40px", height: "40px" }}
        />
        <p style={{ margin: 0, lineHeight: "40px", fontSize: "15px" }}>
          GSTIN : 33AKTPV6692C1ZJ
        </p>
      </div>
      <div style={{ display:'flex',flexDirection:'column',gap:'10px',margin: "20px" }}>
        <h2 className="text-center mb-4">About Us</h2>
        <p>
          Welcome to <strong>Venba Interlock Bricks</strong>, a trusted leader
          in the interlock brick industry with over <strong>14 years of experience.</strong> We
          are committed to <strong>making homeownership more accessible</strong> through
          innovative and customer-focused solutions.
        </p>
        <p>
          Our services include the <strong>convenience of purchasing interlock bricks
          online</strong> with <strong>flexible installment plans.</strong> Customers who meet a specified
          purchase threshold can also take advantage of our <strong>affordable house
          construction services,</strong> combining <strong>quality and cost-effectiveness</strong> to
          bring their dream homes to life.
        </p>
        <p>
          In line with our dedication to <strong>community welfare,</strong> we allocate a
          portion of our profits to give back. Each year, up to <strong>four registered
          customers</strong> are selected for <strong>free home construction,</strong> helping more
          families achieve their homeownership dreams.
        </p>
        <p>
          At <strong>Venba Interlock Bricks</strong>, we don’t just construct
          houses—we <strong>build futures.</strong> Together, let’s create a <strong>brighter tomorrow.</strong>
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
