import React from "react";
import logo from "../Images/logo.png";
import GSTN_logo from "../Images/GSTN_venba.png";
import { Link } from "react-router-dom";

const PricingPolicy = () => {
  return (
    <div class="container">
      <div className="d-flex justify-content-center mt-2">
        <Link to="/">
          <img
            src={logo}
            alt="logo"
            style={{ width: "234px", height: "130px" }}
          />
        </Link>
      </div>
      <div
        className="d-flex justify-content-center align-items-center gap-2"
        style={{ height: "50px" }}
      >
        <img
          src={GSTN_logo}
          alt="GST"
          style={{ width: "40px", height: "40px" }}
        />
        <p style={{ margin: 0, lineHeight: "40px", fontSize: "15px" }}>
          GSTIN : 33AKTPV6692C1ZJ
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          margin: "20px",
        }}
      >
        <h2 className="text-center mb-4">Pricing Policy</h2>

        <h5>1. Pricing Structure</h5>
        <ul>
          <li>
            The prices of stones are determined by the seller and are listed on
            the website.
          </li>
          <li>
            Prices are subject to change without prior notice and may vary due
            to market conditions or manufacturing costs.
          </li>
        </ul>

        <h5>2. Additional Charges</h5>
        <ul>
          <li>
            Delivery and shipping charges are listed on the website and may vary
            over time.
          </li>
        </ul>

        <h5>3. Bulk Order Pricing</h5>
        <ul>
          <li>
            Special pricing for bulk orders is available at the sole discretion
            of the seller.
          </li>
        </ul>

        <h5>4. Payment Terms</h5>
        <ul>
          <li>All payments must be made in advance through our website.</li>
          <li>Only confirmed payments will be processed for delivery.</li>
        </ul>

        <h5>5. Price Adjustments</h5>
        <ul>
          <li>
            Discounts, promotions, or adjustments, if any, are determined solely
            by the seller and are non-negotiable.
          </li>
        </ul>

        <h5>6. Refund Exclusions</h5>
        <ul>
          <li>
            Refunds, if applicable, will exclude delivery and return costs.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PricingPolicy;
