import React from "react";
import logo from "../Images/logo.png";
import GSTN_logo from "../Images/GSTN_venba.png";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div class="container">
      <div className="d-flex justify-content-center mt-2">
        <Link to="/">
          <img
            src={logo}
            alt="logo"
            style={{ width: "234px", height: "130px" }}
          />
        </Link>
      </div>
      <div
        className="d-flex justify-content-center align-items-center gap-2"
        style={{ height: "50px" }}
      >
        <img
          src={GSTN_logo}
          alt="GST"
          style={{ width: "40px", height: "40px" }}
        />
        <p style={{ margin: 0, lineHeight: "40px", fontSize: "15px" }}>
          GSTIN : 33AKTPV6692C1ZJ
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          margin: "20px",
        }}
      >
        <h2 className="text-center mb-4">Contact Us</h2>
        <p>
          We’re here to help! Whether you need more information about our
          products or want to place a bulk order, feel free to reach out to us
          using the details below.
        </p>

        <h5>Address:</h5>
        <p style={{ display: "flex", flexDirection: "column" }}>
          <strong>VENBA INTERLOCK BRICKS</strong>
          <span>SF No. 361/1 B, Salem Byepass Road,</span>{" "}
          <span>Karur - 639006,</span> <span>Tamil Nadu, India.</span>
        </p>

        <h5>Phone:</h5>
        <p>📞 +91 98941 48456</p>

        <h5>Email:</h5>
        <p>📧 vibonline456@gmail.com</p>

        <h5>For Orders:</h5>
        <div>
          <p>
            To order a larger quantity of bricks or inquire about pricing and
            delivery options, please call us at:
          </p>
          <p>📞 +91 98941 48456</p>

          <p>
            We look forward to assisting you with your interlock brick needs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
