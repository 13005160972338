import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import React from "react";
import footerBg from "../Images/footer.jpg"; // Import your footer background image
import banner1 from "../Images/Banner_one.jpg";

function Footer() {
  return (
    <>
      {/* Footer container with background image */}
      <div
        style={{
          position: "relative",
          padding: "40px 0",
          background: `url(${footerBg}) no-repeat center center/cover`,
          backgroundSize: "cover",
        }}
      >
        {/* Overlay to dim the background */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1,
          }}
        ></div>

        {/* Footer content */}
        <div
          className="container d-flex justify-content-between"
          style={{ flexWrap: "wrap", position: "relative", zIndex: 2 }}
        >
          {/* Company Information */}
          <div className="p-3 text-white m-3" style={{ width: "375px" }}>
            <h3 className="text-white">VENBA INTERLOCK BRICKS</h3>
            <p
              className="d-flex gap-2 align-items-center"
              style={{ width: "280px" }}
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <span>SF No.361/1 B, Salem Byepass Road,</span>
            </p>
            <p style={{ marginLeft: "20px" }}>Karur - 639006. Tamil Nadu.</p>
            <p className="d-flex gap-2 align-items-center">
              <FontAwesomeIcon icon={faPhoneAlt} flip="horizontal" />
              <a href="tel:9894148456" className="text-white">
                {" "}
                Phone : 98941 48456
              </a>
            </p>
            <p className="d-flex gap-2 align-items-center">
              <FontAwesomeIcon icon={faEnvelope} /> Email:
              vibonline456@gmail.com
            </p>
            <h5>GSTIN: 33AKTPV6692C1ZJ</h5>
          </div>

          {/* Services Section */}
          <div className="p-3 text-white m-3" style={{ width: "300px" }}>
            <div>
              <h3>Services & Info</h3>
              <Link
                to="/About-Us"
                style={{
                  textDecoration: "none",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <p>About Us</p>
              </Link>
              <Link
                to="/Contact-us"
                style={{
                  textDecoration: "none",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <p>Contact Us</p>
              </Link>
              <Link
                to="/privacy-policy"
                style={{
                  textDecoration: "none",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <p>Privacy Policy</p>
              </Link>
              <Link
                to="/Pricing-policy"
                style={{
                  textDecoration: "none",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <p>Pricing Policy</p>
              </Link>
              <Link
                to="/refund&cancellation"
                style={{
                  textDecoration: "none",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <p>Refund and Cancellation Policy</p>
              </Link>
              <Link
                to="/terms&condition"
                style={{
                  textDecoration: "none",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <p>Terms & Conditions</p>
              </Link>
            </div>

            <div style={{ display: "flex", marginTop: "80px", gap: "10px" }}>
              <img
                src={banner1}
                alt="Brick Banner-1"
                style={{ width: "260px" }}
              />

              {/* <div style={{display:'flex',flexDirection:'column'}}>
              <h6 style={{height:'30px'}}>Guaranteed  Safe Payments</h6>
              <div style={{display:'flex',gap:'10px'}}>
                <img src={ssl} alt="SSL" style={{ width: '50px', height: '30px' }} />
                <img src={ccavenue} alt="CCAvenue" style={{ width: '50px', height: '30px' }} />
                <img src={mastercard} alt="Mastercard" style={{ width: '50px', height: '30px' }} />
              <img src={visa} alt="Visa" style={{ width: '50px', height: '30px' }} />
              <img src={upi} alt="UPI" style={{ width: '50px', height: '30px' }} />
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
