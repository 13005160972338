import React from "react";
import logo from "../Images/logo.png";
import GSTN_logo from "../Images/GSTN_venba.png";
import { Link } from "react-router-dom";

const TermsCondition = () => {
  return (
    <div className="container">
      <div className="d-flex justify-content-center mt-2">
        <Link to="/">
          <img
            src={logo}
            alt="logo"
            style={{ width: "234px", height: "130px" }}
          />
        </Link>
      </div>
      <div
        className="d-flex justify-content-center align-items-center gap-2"
        style={{ height: "50px" }}
      >
        <img
          src={GSTN_logo}
          alt="GST"
          style={{ width: "40px", height: "40px" }}
        />
        <p style={{ margin: 0, lineHeight: "40px", fontSize: "15px" }}>
          GSTIN : 33AKTPV6692C1ZJ
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          margin: "20px",
        }}
      >
        <h2 className="text-center mb-4">Terms and Conditions</h2>

        <h5>1. Product Information</h5>
        <ul>
          <li>
            The images of stones displayed on the website are representative and
            closely match the actual product. Minor or macro-level variations
            may occur.
          </li>
          <li>
            Details regarding combinations and raw materials are not available
            online. For demonstrations and illustrations, in-person visits are
            recommended.
          </li>
        </ul>

        <h5>2. Quantity Verification</h5>
        <ul>
          <li>
            Buyers are advised to verify the quantity of stones upon receipt.
          </li>
          <li>
            Any discrepancies in the delivery must be reported within 24 hours
            of receipt.
          </li>
        </ul>

        <h5>3. Booking and Delivery</h5>
        <ul>
          <li>
            Bookings must be made in advance, with a lead time of 15–30 days for
            delivery.
          </li>
          <li>
            Delivery timelines depend on product availability and may vary due
            to unforeseen circumstances.
          </li>
        </ul>

        <h5>4. Payment Terms</h5>
        <ul>
          <li>All purchases require advance payment through the website.</li>
          <li>Delivery will only proceed upon confirmation of payment.</li>
        </ul>

        <h5>5. Retail and Bulk Orders</h5>
        <ul>
          <li>Stones are available for retail purchase.</li>
          <li>
            Bulk orders are accepted at the sole discretion of the seller.
          </li>
        </ul>

        <h5>6. Liability and Usage</h5>
        <ul>
          <li>
            The seller is not liable for damage or misuse of the product due to
            negligence.
          </li>
          <li>
            Products are designed for specific usage as per the provided
            specifications and are highly durable when used accordingly.
          </li>
          <li>
            The seller holds no responsibility for damages caused by force
            majeure events or unusual weather conditions.
          </li>
        </ul>

        <h5>7. Returns and Replacements</h5>
        <ul>
          <li>
            Replacement requests beyond the specified terms will be at the
            seller's discretion.
          </li>
          <li>
            Refunds are not provided for negligence, misuse, or mishandling of
            the product.
          </li>
        </ul>

        <h5>8. Shipping and Transit</h5>
        <ul>
          <li>
            The seller is responsible for the product during shipping and
            transit.
          </li>
          <li>
            Delivery charges, as stated on the website, apply and may vary.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TermsCondition;
